@import url(https://fonts.googleapis.com/css?family=Raleway:600);
@import url(https://fonts.googleapis.com/css?family=Nunito);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, html {
    height: 100%;
    background-color: #fbfbfb;
}
.row
{
    margin-right: 0;
    margin-left: 0;
}
.parallax {
    /* The image used */
    background-image: url("/img/nyhavn.jpeg");

    /* Full height */
    height: 720px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.portfolio-wrapper
{
    margin-top: -270px;
    padding: 30px;
}
.container-fluid
{
    padding-left: 0;
    padding-right: 0;
}
.type-wrapper
{
    color: #fbfbfb;
    margin-bottom: 20px;
    font-size: 50px;
    text-shadow: 0px 0px 20px black;
    height: 60px;
}
h1
{
    margin-top: -250px;
    color : #fbfbfb;
    font-family: 'Raleway', sans-serif;
    font-size: 70px;
    text-shadow: 0px 0px 20px black;
}
.btn-contact
{
    background-color: #008fea;
    color : white;
    width: 100%;
    margin: 50px 0;
    padding: 15px;
    font-size: 25px;
}
.btn-contact:hover
{
    color : white;
    box-shadow: 0px 10px 10px #a4a4a4;
}
.spin{-webkit-animation:spin 2s infinite linear;animation:spin 2s infinite linear}
@-webkit-keyframes spin{
    0%{
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg)
    }
    100%{
        -webkit-transform:rotate(359deg);
                transform:rotate(359deg)
    }
}
@keyframes spin{
    0%{
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg)
    }
    100%{
        -webkit-transform:rotate(359deg);
                transform:rotate(359deg)
    }
}
footer
{
    margin-top: 20px;
}
.fa-react
{
    font-size : 18px;
}
.techno
{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    flex-wrap: wrap;
    -webkit-animation:opac 2s;
            animation:opac 2s;
}
@-webkit-keyframes opac{from{opacity:0} to{opacity:1}}
@keyframes opac{from{opacity:0} to{opacity:1}}
.techno-icon
{
    height: 50px;
    margin: 10px;
}
.btn-down
{
    color : white;
    font-size: 50px;
    margin-left: 48%;
    margin-bottom: 150px;
}
.btn-down:hover
{
    color : #008fea;
    text-shadow: 0px 0px 5px #333333;
}
@media screen and (max-width: 992px) {
    #main-title
    {
        font-size : 50px;
    }
    #typed-text
    {
        font-size: 25px;
    }
    .btn-down
    {
        font-size: 40px;
        margin-top: 50px;
        margin-left: 44%;
    }
    .btn-options-wrapper
    {
        display: none;
    }
    .btn-contact
    {
        font-size: 20px;
    }
}

.mobile-view
{
    margin-top: 20px;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-btn {
    background-color: transparent;
    height: 50px;
    -webkit-perspective: 1000px;
            perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-btn-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flipped {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-btn-front, .flip-btn-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-btn-front {
    background-color: #bbb;
    color: black;
}

/* Style the back side */
.flip-btn-back {
    background-color: dodgerblue;
    color: white;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}
.portfolio-item
{
    box-shadow: 0px 15px 10px #a4a4a4;
    margin-top : 35px;
    padding: 15px;
    background-color: white;
    border-radius: 2px;
    position: relative;

}
.portfolio-title
{
    font-family: 'Nunito', sans-serif;
}
h1, .portfolio-item, .btn-options-wrapper
{
    -webkit-animation:opac 2s;
            animation:opac 2s;
}
.btn-options-wrapper
{
    -webkit-animation:opac 3s;
            animation:opac 3s;
}
@-webkit-keyframes opac{from{opacity:0} to{opacity:1}}
@keyframes opac{from{opacity:0} to{opacity:1}}


.portfolio-img {
    position: relative;
    cursor: pointer;
}

.website-img {
    display: block;
    width: 100%;
    height: auto;
    transition: max-height 2s;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(91, 91, 91, 0.7);
}

.portfolio-img:hover .overlay {
    opacity: 1;
}

.overlay-text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    text-shadow:  0px 0px 20px black;
}
.btn-mobile
{
    background-color: #008fea;
    color : white;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    font-size: 25px;
}
.btn-mobile:hover
{
    color : white;
    box-shadow: 0px 10px 10px #a4a4a4;
}
.type-work-container
{
    right: -10px;
    margin-top: -20px;
    position: relative;
    float: right;
    z-index: 1000;
}
.img-type-work
{
    width: 50px;
    cursor: help;
}
@media screen and (max-width: 992px) {
    .img-type-work {
        display: none;
    }
}
.btn-options-wrapper
{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.btn-options
{
    background-color: #008fea;
    color : white;
    position: absolute;
    left: 92vw;
    top : 30px;
    font-size: 30px;
}

.btn-options:hover
{
    color : white;
    box-shadow: 0px 10px 10px #a4a4a4;
}

/* ON/OFF Switch */
.onoffswitch {
    position: relative;
    width: 55px;
    display: inline-block;
    font-size: 80%;
}
.onoffswitch .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid hsl(0, 0%, 90%);
    border-radius: 20px;
    margin: 0;
}
.onoffswitch .onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    transition: margin 0.15s ease-in-out;
}
.onoffswitch .onoffswitch-inner:before,
.onoffswitch .onoffswitch-inner:after {
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 80%;
    color: hsl(0, 0%, 100%);
    font-weight: normal;
    box-sizing: border-box;
}
.onoffswitch .onoffswitch-inner:before {
    content: "";
    padding-left: 10px;
    background-color: hsl(203, 100%, 39.1569%);
    color: red;
}
.onoffswitch .onoffswitch-inner:after {
    content: "";
    padding-right: 10px;
    background-color: hsl(0, 0%, 98%);
    color: hsl(0, 0%, 24%);
    text-align: right;
}
.onoffswitch .onoffswitch-switch {
    width: 22px;
    height: 22px;
    margin: 0;
    background: hsl(0, 0%, 100%);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    position: absolute;
    top: 2px;
    bottom: 0;
    right: 35px;
    transition: right 0.15s ease-in-out;
}
.toggle-group {
    position: relative;
    height: 27px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    top: 50%;
    left: 20px;
    margin-top: -13.5px;
}
.toggle-group input[type=checkbox] {
    position: absolute;
    left: 10px;
}
.toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
    right: 1px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.toggle-group input[type=checkbox]:focus ~ .onoffswitch {
    outline: thin dotted #333;
    outline: 0;
}
.toggle-group label {
    position: absolute;
    cursor: pointer;
    padding-left: 65px;
    display: inline-block;
    text-align: left;
    line-height: 24px;
    width: 100%;
    z-index: 1;
    height: 24px;
    font-weight: 200;
}
/* ==== Accessibility ===== */
.aural {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
}
.aural:focus {
    clip: rect(0, 0, 0, 0);
    font-size: 1em;
    height: auto;
    outline: thin dotted;
    position: static !important;
    width: auto;
    overflow: visible;
}
.switch-btn
{
    margin: 10px;
}
