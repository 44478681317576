@import url('https://fonts.googleapis.com/css?family=Nunito');
.portfolio-item
{
    -moz-box-shadow: 0px 15px 10px #a4a4a4;
    -webkit-box-shadow: 0px 15px 10px #a4a4a4;
    box-shadow: 0px 15px 10px #a4a4a4;
    margin-top : 35px;
    padding: 15px;
    background-color: white;
    border-radius: 2px;
    position: relative;

}
.portfolio-title
{
    font-family: 'Nunito', sans-serif;
}
h1, .portfolio-item, .btn-options-wrapper
{
    animation:opac 2s;
}
.btn-options-wrapper
{
    animation:opac 3s;
}
@keyframes opac{from{opacity:0} to{opacity:1}}


.portfolio-img {
    position: relative;
    cursor: pointer;
}

.website-img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: max-height 2s;
    -moz-transition: max-height 2s;
    transition: max-height 2s;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(91, 91, 91, 0.7);
}

.portfolio-img:hover .overlay {
    opacity: 1;
}

.overlay-text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    text-shadow:  0px 0px 20px black;
}
.btn-mobile
{
    background-color: #008fea;
    color : white;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    font-size: 25px;
}
.btn-mobile:hover
{
    color : white;
    -moz-box-shadow: 0px 10px 10px #a4a4a4;
    -webkit-box-shadow: 0px 10px 10px #a4a4a4;
    box-shadow: 0px 10px 10px #a4a4a4;
}
.type-work-container
{
    right: -10px;
    margin-top: -20px;
    position: relative;
    float: right;
    z-index: 1000;
}
.img-type-work
{
    width: 50px;
    cursor: help;
}
@media screen and (max-width: 992px) {
    .img-type-work {
        display: none;
    }
}