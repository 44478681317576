.btn-options-wrapper
{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.btn-options
{
    background-color: #008fea;
    color : white;
    position: absolute;
    left: 92vw;
    top : 30px;
    font-size: 30px;
}

.btn-options:hover
{
    color : white;
    -moz-box-shadow: 0px 10px 10px #a4a4a4;
    -webkit-box-shadow: 0px 10px 10px #a4a4a4;
    box-shadow: 0px 10px 10px #a4a4a4;
}

/* ON/OFF Switch */
.onoffswitch {
    position: relative;
    width: 55px;
    display: inline-block;
    font-size: 80%;
}
.onoffswitch .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid hsl(0, 0%, 90%);
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    margin: 0;
}
.onoffswitch .onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    -webkit-transition: margin 0.15s ease-in-out;
    -o-transition: margin 0.15s ease-in-out;
    -moz-transition: margin 0.15s ease-in-out;
    transition: margin 0.15s ease-in-out;
}
.onoffswitch .onoffswitch-inner:before,
.onoffswitch .onoffswitch-inner:after {
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 80%;
    color: hsl(0, 0%, 100%);
    font-weight: normal;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.onoffswitch .onoffswitch-inner:before {
    content: "";
    padding-left: 10px;
    background-color: hsl(203, 100%, 39.1569%);
    color: red;
}
.onoffswitch .onoffswitch-inner:after {
    content: "";
    padding-right: 10px;
    background-color: hsl(0, 0%, 98%);
    color: hsl(0, 0%, 24%);
    text-align: right;
}
.onoffswitch .onoffswitch-switch {
    width: 22px;
    height: 22px;
    margin: 0;
    background: hsl(0, 0%, 100%);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    bottom: 0;
    right: 35px;
    -webkit-transition: right 0.15s ease-in-out;
    -o-transition: right 0.15s ease-in-out;
    -moz-transition: right 0.15s ease-in-out;
    transition: right 0.15s ease-in-out;
}
.toggle-group {
    position: relative;
    height: 27px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    top: 50%;
    left: 20px;
    margin-top: -13.5px;
}
.toggle-group input[type=checkbox] {
    position: absolute;
    left: 10px;
}
.toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
    right: 1px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.toggle-group input[type=checkbox]:focus ~ .onoffswitch {
    outline: thin dotted #333;
    outline: 0;
}
.toggle-group label {
    position: absolute;
    cursor: pointer;
    padding-left: 65px;
    display: inline-block;
    text-align: left;
    line-height: 24px;
    width: 100%;
    z-index: 1;
    height: 24px;
    font-weight: 200;
}
/* ==== Accessibility ===== */
.aural {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
}
.aural:focus {
    clip: rect(0, 0, 0, 0);
    font-size: 1em;
    height: auto;
    outline: thin dotted;
    position: static !important;
    width: auto;
    overflow: visible;
}
.switch-btn
{
    margin: 10px;
}