@import url('https://fonts.googleapis.com/css?family=Raleway:600');
body, html {
    height: 100%;
    background-color: #fbfbfb;
}
.row
{
    margin-right: 0;
    margin-left: 0;
}
.parallax {
    /* The image used */
    background-image: url("/img/nyhavn.jpeg");

    /* Full height */
    height: 720px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.portfolio-wrapper
{
    margin-top: -270px;
    padding: 30px;
}
.container-fluid
{
    padding-left: 0;
    padding-right: 0;
}
.type-wrapper
{
    color: #fbfbfb;
    margin-bottom: 20px;
    font-size: 50px;
    text-shadow: 0px 0px 20px black;
    height: 60px;
}
h1
{
    margin-top: -250px;
    color : #fbfbfb;
    font-family: 'Raleway', sans-serif;
    font-size: 70px;
    text-shadow: 0px 0px 20px black;
}
.btn-contact
{
    background-color: #008fea;
    color : white;
    width: 100%;
    margin: 50px 0;
    padding: 15px;
    font-size: 25px;
}
.btn-contact:hover
{
    color : white;
    -moz-box-shadow: 0px 10px 10px #a4a4a4;
    -webkit-box-shadow: 0px 10px 10px #a4a4a4;
    box-shadow: 0px 10px 10px #a4a4a4;
}
.spin{animation:spin 2s infinite linear}
@keyframes spin{
    0%{
        transform:rotate(0deg)
    }
    100%{
        transform:rotate(359deg)
    }
}
footer
{
    margin-top: 20px;
}
.fa-react
{
    font-size : 18px;
}
.techno
{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    flex-wrap: wrap;
    animation:opac 2s;
}
@keyframes opac{from{opacity:0} to{opacity:1}}
.techno-icon
{
    height: 50px;
    margin: 10px;
}
.btn-down
{
    color : white;
    font-size: 50px;
    margin-left: 48%;
    margin-bottom: 150px;
}
.btn-down:hover
{
    color : #008fea;
    text-shadow: 0px 0px 5px #333333;
}
@media screen and (max-width: 992px) {
    #main-title
    {
        font-size : 50px;
    }
    #typed-text
    {
        font-size: 25px;
    }
    .btn-down
    {
        font-size: 40px;
        margin-top: 50px;
        margin-left: 44%;
    }
    .btn-options-wrapper
    {
        display: none;
    }
    .btn-contact
    {
        font-size: 20px;
    }
}
